import { render, staticRenderFns } from "./Products.vue?vue&type=template&id=ccc346ea"
import script from "./Products.vue?vue&type=script&lang=js"
export * from "./Products.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RkSkeleton: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkSkeleton/RkSkeleton.vue').default,RkProductCardLoading: require('/var/www_vsf/htdocs/rockitUI/components/components/organisms/RkProductCard/_internal/RkProductCardLoading.vue').default,RkSection: require('/var/www_vsf/htdocs/rockitUI/components/components/molecules/RkSection/RkSection.vue').default})
