//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api';
import { boolean } from 'yup/lib/locale';
export default defineComponent({
  name: 'RkSection',
  props: {
    titleHeading: {
      type: String,
      default: '',
    },
    subtitleHeading: {
      type: String,
      default: '',
    },
    levelHeading: {
      type: Number,
      default: 2,
    },
    sizeHeading: {
      type: Number,
      default: 4,
    },
    alignHeading: {
      type: String,
      default: 'text-left',
    },
    verticalPadding: {
      type: Boolean,
      default: true,
    },
  },
});
