//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, useFetch, ref, onMounted } from '@nuxtjs/composition-api';
import { useProduct } from '~/composables';
import { SortEnum } from '~/modules/GraphQL/types';

export default defineComponent({
  props: {
    blok: {
      type: Object,
      required: true,
    },
    isFetching: {
      type: Boolean,
      required: true,
      default: true,
    },
    verticalPadding: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  components: {
    ProductCarousel: () =>
      import(
        /* webpackPrefetch: true */ '~/modules/catalog/product/components/ProductsCarousel.vue'
      ),
  },
  setup(props) {
    const { getProductList } = useProduct();
    const products = ref([]);
    const loading = ref(true);

    // using onMounted instead of useFetch because useFetch is not working with storyblok and currency switching
    onMounted(async () => {
      const getProducts = await getProductList({
        pageSize: 8,
        currentPage: 1,
        filter: {
          category_id: {
            eq: props.blok.category,
          },
        },
        sort: {
          position: SortEnum.Asc,
        },
      });

      if (getProducts?.items?.length) {
        products.value = getProducts.items;
      }

      loading.value = false;
    });

    return {
      products,
      loading,
    };
  },
});
