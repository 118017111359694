import { render, staticRenderFns } from "./RkSection.vue?vue&type=template&id=4764172e"
import script from "./RkSection.vue?vue&type=script&lang=js"
export * from "./RkSection.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RkHeading: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkHeading/RkHeading.vue').default,RkSiteWrapper: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkSiteWrapper/RkSiteWrapper.vue').default})
