import { render, staticRenderFns } from "./RkProductCardLoading.vue?vue&type=template&id=7275b968"
import script from "./RkProductCardLoading.vue?vue&type=script&lang=ts"
export * from "./RkProductCardLoading.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RkSkeleton: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkSkeleton/RkSkeleton.vue').default})
